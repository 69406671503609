/* ibm-plex-sans-200 - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 200;
  src: local(''),
  url('../Webfont/ibm-plex-sans-v14-latin-200.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../Webfont/ibm-plex-sans-v14-latin-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-sans-200italic - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 200;
  src: local(''),
  url('../Webfont/ibm-plex-sans-v14-latin-200italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../Webfont/ibm-plex-sans-v14-latin-200italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-sans-300 - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: local(''),
  url('../Webfont/ibm-plex-sans-v14-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../Webfont/ibm-plex-sans-v14-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-sans-300italic - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: local(''),
  url('../Webfont/ibm-plex-sans-v14-latin-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../Webfont/ibm-plex-sans-v14-latin-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-sans-regular - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('../Webfont/ibm-plex-sans-v14-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../Webfont/ibm-plex-sans-v14-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-sans-italic - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: local(''),
  url('../Webfont/ibm-plex-sans-v14-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../Webfont/ibm-plex-sans-v14-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-sans-500 - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: local(''),
  url('../Webfont/ibm-plex-sans-v14-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../Webfont/ibm-plex-sans-v14-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-sans-500italic - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 500;
  src: local(''),
  url('../Webfont/ibm-plex-sans-v14-latin-500italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../Webfont/ibm-plex-sans-v14-latin-500italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-sans-600 - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local(''),
  url('../Webfont/ibm-plex-sans-v14-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../Webfont/ibm-plex-sans-v14-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-sans-600italic - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: local(''),
  url('../Webfont/ibm-plex-sans-v14-latin-600italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../Webfont/ibm-plex-sans-v14-latin-600italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-sans-700italic - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 700;
  src: local(''),
  url('../Webfont/ibm-plex-sans-v14-latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../Webfont/ibm-plex-sans-v14-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-sans-700 - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src: local(''),
  url('../Webfont/ibm-plex-sans-v14-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../Webfont/ibm-plex-sans-v14-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-sans-condensed-100 - latin */
@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 100;
  src: local(''),
  url('../Webfont/ibm-plex-sans-condensed-v13-latin-100.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../Webfont/ibm-plex-sans-condensed-v13-latin-100.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-sans-condensed-100italic - latin */
@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 100;
  src: local(''),
  url('../Webfont/ibm-plex-sans-condensed-v13-latin-100italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../Webfont/ibm-plex-sans-condensed-v13-latin-100italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-sans-condensed-200 - latin */
@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 200;
  src: local(''),
  url('../Webfont/ibm-plex-sans-condensed-v13-latin-200.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../Webfont/ibm-plex-sans-condensed-v13-latin-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-sans-condensed-200italic - latin */
@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 200;
  src: local(''),
  url('../Webfont/ibm-plex-sans-condensed-v13-latin-200italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../Webfont/ibm-plex-sans-condensed-v13-latin-200italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-sans-condensed-300 - latin */
@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 300;
  src: local(''),
  url('../Webfont/ibm-plex-sans-condensed-v13-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../Webfont/ibm-plex-sans-condensed-v13-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-sans-condensed-300italic - latin */
@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 300;
  src: local(''),
  url('../Webfont/ibm-plex-sans-condensed-v13-latin-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../Webfont/ibm-plex-sans-condensed-v13-latin-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-sans-condensed-italic - latin */
@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 400;
  src: local(''),
  url('../Webfont/ibm-plex-sans-condensed-v13-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../Webfont/ibm-plex-sans-condensed-v13-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-sans-condensed-regular - latin */
@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('../Webfont/ibm-plex-sans-condensed-v13-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../Webfont/ibm-plex-sans-condensed-v13-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-sans-condensed-500 - latin */
@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 500;
  src: local(''),
  url('../Webfont/ibm-plex-sans-condensed-v13-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../Webfont/ibm-plex-sans-condensed-v13-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-sans-condensed-500italic - latin */
@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 500;
  src: local(''),
  url('../Webfont/ibm-plex-sans-condensed-v13-latin-500italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../Webfont/ibm-plex-sans-condensed-v13-latin-500italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-sans-condensed-600 - latin */
@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 600;
  src: local(''),
  url('../Webfont/ibm-plex-sans-condensed-v13-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../Webfont/ibm-plex-sans-condensed-v13-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-sans-condensed-600italic - latin */
@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 600;
  src: local(''),
  url('../Webfont/ibm-plex-sans-condensed-v13-latin-600italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../Webfont/ibm-plex-sans-condensed-v13-latin-600italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-sans-condensed-700 - latin */
@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 700;
  src: local(''),
  url('../Webfont/ibm-plex-sans-condensed-v13-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../Webfont/ibm-plex-sans-condensed-v13-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-sans-condensed-700italic - latin */
@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 700;
  src: local(''),
  url('../Webfont/ibm-plex-sans-condensed-v13-latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../Webfont/ibm-plex-sans-condensed-v13-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}