/*BASE*/
html {
  font-size: 10px;
}

body, html {
  height: 100%;
  min-height: 100%;
}

body {
  background: $grey-light;
  color: $font;
  &.navOpen {
    overflow: hidden;
    overflow-y: hidden;
    overflow-x: hidden;
    height: 100vH;
    max-height: 100vH;
  }
}

#wrap {
  max-width: 1420px;
  margin: 0 auto;
  background: #fff;
  overflow: hidden;
}

main {
  margin: 50px 0;
}

* {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  &:focus {
    outline: none !important;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

figure, blockquote {
  margin: 0;
}

.row.centered {
  text-align: center;
  vertical-align: top;
  [class*="col-"] {
    text-align: left;
    margin-right: -4px;
    display: inline-block;
    vertical-align: top;
    float: none;
  }
}

.centered {
  text-align: center;
}

.table {
  display: table;
  .tableCell {
    display: table-cell;
    vertical-align: middle;
  }
}

.container .container{
  padding: 0 !important;
  width: 100% !important;
}


/*==========  BREAKPOINTS  MAX-WIDTH ==========*/
@media only screen and (max-width: 1399px) {
  html{
    font-size: 0.714796283059vW;
  }
}
@media only screen and (max-width: $screen-sm-max) {
  html{
    font-size: 1vW;
  }
}
@media only screen and (max-width: 1100px) {
  html{font-size: 0.88vW}
  .mainnav nav ul:not(.layernav)>li.divider {
    padding: 30px 2rem;
  }
}
@media only screen and (max-width: 999px) {
  html{font-size: 10px;}
}
@media only screen and (max-width: 767px) {

  .scrollTop {
    transform: scale(0.55);
    right: 0;
    bottom: 0;
  }
  .row.centered {
    text-align: center;
    .col-lg-24, .col-md-24, .col-sm-24, .col-xs-24 {
      margin-right: 0;
      width: 100%;
      display: block;
      float: none;
    }
  }
}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/
@media only screen and (max-width: 600px) {
  html{
    font-size: 8.8px;
  }
  main {
    margin: 25px 0 50px;
  }
}

@media only screen and (min-width: 1050px) {
  .container {
    width: 100%;
  }
}

@media only screen and (min-width: 1400px) {
  .container {
    width: 1300px;
  }
}

@media only screen and (max-width: 999px) {
  .col-md-6 + .col-md-6, aside{
    margin-top: 5rem;
  }
}