/*Main Navigation*/
nav ul li a:before{
  //display: none;
}
.mainnav {
  background: #fff;
  position: relative;
  width: 100%;
  font-family: $helv-cond_medium;
  nav {
    border-bottom: 1px solid $grey-light;
    ul:not(.layernav) {
      > li {
        display: inline-block;
        margin-right: -4px;
        font-size: 2.6rem;
        color: $font;
        padding: 30px 0;
        position: relative;
        > a {
          color: $font;
          font-weight: 500;
          &:hover, &:focus{
            color: $orange !important;
          }
        }
        &.divider {
          padding: 30px 2.5rem;
        }
        &.temp, &:last-child {
          float: right;
          margin: 0;
          padding: 0;
          border:none !important;
          > a {
            color: $grey;
            font-family: $helv-cond_mediun_italic;
            font-weight: 600;
            display: block;
            border: 1px solid $grey-light;
            padding: 1rem 1.8rem 1.2rem;
            margin-top: 22px;
            font-size: 2rem;
            &:hover, &:focus{
              background: $orange;
              border-color: $orange;
              color: #fff !important;
            }
          }
        }
        &:nth-last-child(+2) {
          display: none;
        }
        &.active {
          > a {
            color: $font !important;
            &:after {
              transition: $t-fast;
              position: absolute;
              left: 0;
              bottom: -3px;
              content: "";
              display: block;
              height: 5px;
              background: $orange;
              width: 100%;
            }
          }
          &.layernav-open{
            > a{
              color:$orange;
              &:after{
                bottom:-4px;
                opacity: 0;
              }
            }
          }
        }
      }
    }
  }
}
.layernav{
  cursor: pointer;
  width: 37.5rem;
  background: #fff;
  border: 1px solid $grey-light;
  padding: 0 25px;
  position: absolute;
  top: 200px;
  visibility: hidden;
  opacity: 0;
  z-index: 10;
  left: 50%;
  transform: translateX(-50%);
  transition: $t-medium;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.19);
  > li{
      a{
        display: block;
        font-size: 2.2rem;
        padding: 1rem 0;
        border-bottom: 1px solid $grey-light;
        color: $font;
        &:hover, &:focus{
          color: $orange !important;
        }
      }
    &:last-child{
      a{
        border: none;
      }
    }
    &.active{
      a, a:hover{
        color: $font !important;
        border-color: $orange;
      }
    }
  }
  &:before{
    content: "";
    background: url("../Images/arrow_layernav.svg") no-repeat center bottom -1px;
    width: 28px;
    height: 18px;
    display: block;
    position: absolute;
    left: 50%;
    top:-18px;
    transform: translate3D(-50%, 0, 0);
  }
  .layernav-open &{
    top:100% !important;
    visibility: visible !important;
    opacity: 1 !important;
  }
}
.mainnav li:first-child{
  .layernav{
    left: 0;
    transform: none;
    &:before{
      left: 2.5rem;
      transform: none;
    }
  }
}
.hamburger {
  display: none;
}
.logo, .hamburger{
  transition: $t-fast;
}
/*==========  BREAKPOINTS  MAX-WIDTH ==========*/

@media only screen and (max-width: $screen-lg) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 1100px) {
  .mainnav nav ul:not(.layernav)>li.divider {
    padding: 30px 2rem;
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: $screen-sm-max) {
  header .badge {
    display: none;
  }
  .hamburger {
    cursor: pointer;
    display: block;
    width: 80px;
    height: 80px;
    top: 25%;
    background: $orange;
    position: absolute;
    z-index: 100;
    right: 0;
    text-align: center;
    svg {
      transition: $t-fast;
      display: block;
      left: 50%;
      top: 50%;
      position: absolute;
      transform: translate3d(-50%, -50%, 0) scale(1.4);
      + svg {
        display: none;
      }
      .navOpen & {
        display: none;
        + svg {
          display: block;
        }
      }
    }
    &:hover {
      svg {
        transform: translate3d(-50%, -50%, 0) scale(1.6);
      }
    }
  }
  .mainnav.top {
    position: fixed;
    z-index: 99;
    right: 0;
    top: 0;
    margin: 0;
    padding: 0;
    float: none;
    nav {
      margin: 0;
      padding: 105px 50px 50px 50px;
      position: absolute;
      z-index: 1;
      overflow-y: scroll;
      overflow-x: hidden;
      transition: $t-fast;
      right: 0;
      top: 0;
      width: 100vW;
      height: 100vH;
      background: transparentize($font, .1);
      transform: translateX(100%);
      .navOpen & {
        transform: translateX(0%);
      }
      ul:not(.layernav) > li {
        padding: 1.5rem;
        display: block;
        text-align: left;
        margin: 0;
        border-bottom: 1px solid $grey-light;
        float: none;
        &.divider {
          display: none;
        }
        a {
          font-size: 4vW;
          padding: 2.2vW 0;
          color: #fff;
          &:before, &:after {
            display: none;
          }
        }
        &.active {
          > a {
            color: $orange !important;
          }
        }
        &:last-child{
          a{
            border:none !important;
            margin-top: 0 !important;
            padding: 0;
            line-height: 1;
            font-weight: 500 !important;
            &:hover{
              background-color: transparent !important;
              color: $orange !important;
            }
          }
        }
      }
    }
  }
  .navOpen{
    .logo, .hamburger{
      top:0 !important;
    }
  }
  .layernav{
    position: static;
    opacity: 1;
    visibility: visible;
    width: 100%;
    transform: none;
    box-shadow: none;
    background: none;
    border:none;
    padding: 10px 0 0 0;
    &:before{
      display: none;
    }
    li{
      border:none;
      a{
        font-size: 3vW !important;
        padding: 1.25vW 0 !important;
        color: #eef0f1 !important;
        border: none;
        font-family: $helv-cond_regular;
        font-weight: 400;
      }
      &.active{
        a, a:hover{
          color: $orange !important;
          font-family: $helv-cond_medium;
          font-weight: 600;
        }
      }
    }
  }
}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/

@media only screen and (max-width: 479px) {
  .mainnav.top nav{
    ul:not(.layernav){
      > li{
        padding: 15px 0;
        a {
          font-size: 24px;
          padding: 10px 0;
          color: #fff;
        }
      }
    }
    .layernav li {
      padding: 0;
      a {
        font-size: 18px !important;
      }
    }
  }
}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/

@media only screen and (max-width: 376px) {

}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/

@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/

/*==========  BREAKPOINTS  MIN-WIDTH ==========*/

@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/

@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/

@media only screen and (min-width: $screen-xs) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: $screen-sm) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/

@media only screen and (min-width: $screen-md) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/