/*FOOTERT*/

footer {
  position: relative;
  background: $font;
  padding-top: 3.2rem;
  * {
    color: #fff;
  }

  .mainnav {
    background: none;
    border-bottom: 1px solid $grey-darker;
    nav {
      ul:not(.layernav) li {
        color: #fff;
        a {
          color: #fff;
        }
        &:last-child {
          a {
            color: $orange;
            &:hover, &:focus {
              color: #fff !important;
            }
          }
        }
        &.active{
          a, a:hover{color: #fff !important;}
        }
      }
    }
  }
  .meta {
    position: static;
    background: none;
    padding: 0;
    margin: 3.4rem 0;
    height: auto;
    .copyright {
      display: inline-block;
    }
    nav {
      display: block;
      float: right;
      ul li {
        display: inline-block;
        font-size: 1.6rem;
        line-height: 1;
        a {
          display: block;
          padding: 0;
          font-size: 1.6rem;
          line-height: 1;
        }
      }
    }
  }
  #goTop {
    cursor: n-resize;
    width: 6.8rem;
    height: 6.8rem;
    border-radius: 6.8rem;
    background: $orange;
    border: 1.1rem solid #fff;
    position: absolute;
    left: 50%;
    margin-left: -3.4rem;
    top: -3.4rem;
    transition: $t-fast;
    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3D(-50%, -55%, 0);
      width: 1.8rem;
      transition: $t-fast;
    }
    &:hover, &:focus {
      border-width: 0.7rem;
      img {
      }
    }
  }
}

/*==========  BREAKPOINTS  MAX-WIDTH ==========*/

@media only screen and (max-width: $screen-lg) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 700px) {
  footer {
    .meta {
      .copyright {
        display: block;
        text-align: center;
        margin-bottom: 30px;
      }
      nav {
        float: none;
        display: block;
        text-align: center;
        ul {
          li {
            font-size: 15px;
            a {
              font-size: 15px
            }
          }
        }
      }
    }
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: $screen-sm-max) {
  footer {
    .mainnav {
      display: none;
    }
  }
}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/
@media only screen and (max-width: 415px) {
  footer {
    .meta {
      .copyright {
      }
      nav {
        float: none;
        display: block;
        text-align: center;
        ul {
          li {
            display: block;
            &.divider{display: none;}
            a {
              float:none;
              display: block;
              font-size: 18px;
              padding: 5px 0;
            }
          }
        }
      }
    }
  }
}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/

@media only screen and (max-width: 374px) {

}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/

@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/

/*==========  BREAKPOINTS  MIN-WIDTH ==========*/

@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/

@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/

@media only screen and (min-width: $screen-xs) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: $screen-sm) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/

@media only screen and (min-width: $screen-md) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/