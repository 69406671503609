/*WEBFONTS*/

$helv_regular: 400;
$helv_medium: 600;
$helv_regular_italic: italic;

$helv-cond_regular: 'IBM Plex Sans Condensed', arial, helvetica;
$helv-cond_italic: 'IBM Plex Sans Condensed', arial, helvetica;

$helv-cond_medium: 'IBM Plex Sans Condensed', arial, helvetica;
$helv-cond_mediun_italic: 'IBM Plex Sans Condensed', arial, helvetica;

$helv-ext_medium: 'IBM Plex Sans', arial, helvetica;
$helv-ext_medium_italic: 'IBM Plex Sans', arial, helvetica;

$helv-ext_bold: 700;
$helv-ext_bold_italic: italic;

$helv-ext_heavy: 700;
$helv-ext_heavy_italic: italic;

body, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p {
  font-family: 'IBM Plex Sans', arial, helvetica;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  margin-top: 0;
}

h1, .h1 {
  font-size: 3.6rem
}

h2, .h2 {
  font-size: 2.8rem
}

h3, .h3 {
  font-size: 2.4rem;
  font-weight: 500;
}

h4, .h4 {
  font-size: 2.0rem;
  font-weight: 500;
}

h5, .h5 {
  font-size: 1.6rem;
  font-weight: 500;
}

h6, .h6 {
  font-size: 1.2rem;
  font-weight: 500;
}

h1, .h1, h2.h1 {
  font-weight: 700;
}

h2, .h2 {
  font-weight: 500;
  span {
    display: block;
  }
}

b, strong {
  font-weight: 600;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  a {
    &:hover, &:focus, &:active {

    }
  }
  main & {
    line-height: 120%;
  }
}

nav {
  ul, ul li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

a {
  text-decoration: none;
  transition: $t-fast;
  &:hover, &:focus{
    color: $orange;
  }
}

main {

  a {
    text-decoration: none;
    transition: $t-fast;
    &:hover, &:focus, &:active {
      h1, .h1, h2, .h2 {
      }
    }
    h1, .h1, h2, .h2, p {
      text-decoration: none;
    }
  }

  p, ol li, ul li {
    b, strong {
      font-weight: 600;
    }
  }
  p, .text, address, ul li, ol li {
    font-size: 1.8rem;
    line-height: 1.6128;
    font-weight: normal;
    font-style: normal;
    &.intro, &.big {
      font-size: 2.2rem;
    }
    &.small {
      font-size: 1.4rem;
    }
  }
  figure {
    margin: 0 0 2rem;
  }
  p, ul li, ol li {
    margin: 0 0 2rem;
    &:last-of-type {
    }
    a {
      transition: $t-fast;
      text-decoration: none;
      position: relative;
      color: $font;
      &:before {
        transition: $t-fast;
        position: absolute;
        display: block;
        content: "";
        height: 1px;
        background: $orange;
        left: 0;
        bottom: -3px;
        width: 100%;
      }
      &:hover, &:focus, &:active {
        color: $orange;
        &:before {
          background: $grey-light;
        }
      }
    }
  }
  ul, ol {
    margin: 0 0 2rem;
    padding: 0 0 0 41px;
    nav & {
      padding: 0;
      margin: 0;
      li {
        list-style: none;
        margin: 0;
        a {
          color: $grey;
          text-decoration: none;
          &:hover, &:focus {
            color: $orange !important;
          }
        }
      }
    }
  }
  ul {
    li {
      list-style-image: url("../Images/li-bullet.png");
      list-style-position: outside;
      margin-bottom: 5px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  .text {
    text-align: left;
  }

  .text-center {
    text-align: center;
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, p {
      text-align: center;
    }
    h1, .h1, h2, .h2 {
    }
    h2, .h2 {
    }
    p:last-child {

    }
  }
}

//END main
hr {
  margin: 0;
  border: none;
  height: 0;
  border-top: 1px solid #ccc;
  padding: 0;
}

blockquote {
  font-size: 3.6rem;
  font-weight: normal;
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (max-width: 767px) {
  h1, .h1, h2, .h2 {
    &.icon {
      img {
      }
    }
  }
  blockquote {
    font-size: 1.4rem;
  }
  br {
    display: none;
  }
}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/

/* Extra Small Devices, Phones */
@media only screen and (max-width: 479px) {
  h1, .h1, h2, .h2 {
    hyphens: auto;
  }
  h2, .h2 {
    hyphens: auto;
    br {
      display: none;
    }
  }
  h1, .h1 {
    font-size: 2.8rem
  }
  h2, .h2 {
    font-size: 2.4rem
  }
  h3, .h3 {
    font-size: 1.6rem
  }
  h4, .h4 {
    font-size: 1.4rem
  }
  h5, .h5 {
    font-size: 1.2rem
  }
  h6, .h6 {
    font-size: 1.0rem
  }
}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/

/* Custom, iPhone Retina */
@media only screen and (max-width: 374px) {
  h1, .h1, h2, .h2 {
  }
}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/


