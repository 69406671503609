/*HEADER*/

header {
  position: relative;
  .logo {
    position: absolute;
    display: block;
    z-index: 110;
    top: 15%;
    left: -1px;
    width: 25vW;
    min-width: 250px;
    max-width: 450px;
    transition: $t-fast;
    box-shadow: 0 0 20px rgba(0,0,0,0.0);
    &:hover, &:focus{
      box-shadow: 0 10px 20px rgba(0,0,0,0.2);
      transform: scale(1.05);
      transform-origin: left center;
    }
  }
  .claim {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 10%;
    background: transparentize(#fff, .1);
    text-align: left;
    padding: 2vW 0;
    h1, .h1 {
      display: block;
      font-size: 4.8vW;
      text-align: left;
      font-weight: 700;
      margin: 0;
      span {
        display: block;
        color: $orange;
        font-size: 2.4vW;
        padding-bottom: 5px;
      }
    }
  }
  &.sub, .sub & {
    max-height: 400px;
    overflow: hidden;
    .badge, .claim {
      display: none;
    }
    .logo {
      top: 25%;
    }
  }
}

@mixin gradient_grey {
  background: #c0c6c8;
  background: -moz-linear-gradient(top, #c0c6c8 0%, #8c969a 50%);
  background: -webkit-linear-gradient(top, #c0c6c8 0%, #8c969a 50%);
  background: linear-gradient(to bottom, #c0c6c8 0%, #8c969a 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c0c6c8', endColorstr='#8c969a', GradientType=0);
}

.badge {
  display: block;
  position: absolute;
  right: 5%;
  top: 15%;
  width: 15rem;
  padding-top: 15rem;
  border-radius: 10rem;
  text-align: center;
  transform: rotate(12.5deg);
  transition: $t-fast;
  @include gradient_grey;
  &:before {
    transition: $t-fast;
    content: "";
    display: block;
    position: absolute;
    left: -1.2rem;
    top: -1.2rem;
    width: 16.4rem;
    height: 16.4rem;
    border: 0.5rem solid $orange;
    border-radius: 10rem;
  }
  .table {
    position: absolute;
    left: 0;
    top: 0;
    width: 15rem;
    height: 15rem;
    padding: 0 1.8rem;
    .h1, .h2 {
      color: #fff;
    }
    .h1 {
      font-family: $helv-cond_medium;
      font-weight: 600;
      margin-bottom: 0.7rem;
      font-size: 2rem;
    }
    .h2 {
      font-family: $helv-cond_italic;
      font-style: italic;
      font-size: 1.8rem;
    }
  }
  &:hover, &:focus {
    transform: scale(1.1);
    &:before {
      transform: scale(1.15);
    }
  }
}

.meta {
  font-family: $helv-cond_medium;
  height: 4.3rem;
  font-size: 1.6rem;
  padding: 0 45px;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  background: $grey-darker;
  color: #fff;
  nav {
    display: inline-block;
    a {
      display: inline-block;
      color: #fff;
      padding: 1.3rem 0;
      &:hover, &:focus {
        color: $orange;
      }
    }
    &.left {
      a {
        position: relative;
        padding-left: 30px;
        margin-right: 15px;
        &:last-child {
          margin-right: 0;
        }
        img {
          position: absolute;
          left: 0;
          top: 1.1rem;
          height: 2rem;
          width: auto;
        }
      }
    }
    &.right {
      float: right;
    }
    .divider {
      display: inline-block;
      padding: 0 10px;
    }
  }
}

/*==========  BREAKPOINTS  MAX-WIDTH ==========*/

@media only screen and (max-width: $screen-lg) {
  .meta {
    padding: 0 30px;
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: $screen-md-max) {
  header {
    .claim {

    }
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: $screen-sm-max) {
  header {
    .heroImg {
      width: 120%;
    }
    .claim {
      padding: 2.5rem 0;
      h1, .h1 {
        font-size: 3.8rem;
        span {

        }
      }
    }
  }

}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/

@media only screen and (max-width: 600px) {
  header {
    .heroImg {
      width: 150%;
    }
    .logo {
      max-width: 160px;
      min-width: 160px;
    }
    .hamburger {
      height: 51px;
      width: 59px;
      svg {
        transform: translate3d(-50%, -50%, 0) scale(1)
      }
    }
    .claim {
      padding: 2rem 0;
      h1, .h1 {
        font-size: 3.4rem;
        span {

        }
      }
    }
  }

}

/*END BREAKPOINT MAX 600px-------------------------------------------------*/

@media only screen and (max-width: 479px) {
  header {
    .claim {
      bottom:14px;
      h1 {
        font-size: 2.8rem;
        span {
          font-size: 12px
        }
      }
    }
    .hamburger {
      position: fixed;
      top: 55px
    }
    .logo {
      top: 55px !important;
    }
  }
  .meta {
    padding: 0 15px;
    nav.right {
      display: none;
    }
  }
}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/

@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/

/*==========  BREAKPOINTS  MIN-WIDTH ==========*/

@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/

@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/

@media only screen and (min-width: $screen-xs) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: $screen-sm) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/

@media only screen and (min-width: $screen-md) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/
@media only screen and (min-width: 1441px) {
  header {
    .claim {
      padding: 30px 0;
      h1 {
        font-size: 70px;
        span {
          font-size: 35px;
        }
      }
    }
  }

}