.col3Box{
  margin: 5rem 0;
  .box{
    padding: 0 30px 25px;
    border: 1px solid $grey-light;
    background: #fff;
    .headline{
      font-size: 2.2rem;
      line-height: 1;
      text-transform: uppercase;
      background: $grey-ultralight;
      padding: 25px;
      margin: 0 -30px 25px;
    }
    .content{
      font-size: 2.2rem;
      font-weight: 400;
      font-style: italic;
      a{
        display: block;
        position: relative;
        color: $grey;
        margin-bottom: 15px;
        &:after{
          position: absolute;
          top:5px;
          right: 0;
          display: block;
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0.8rem 0 0.8rem 1.3rem;
          border-color: transparent transparent transparent $orange;
        }
        &:hover, &:focus{
          color: $orange;
        }
      }
    }
    .sumbit-box{
      text-align: right;
    }
    form{
      button,input{
        transition: $t-fast;
        &[type="submit"]{
          display: inline-block;
          color: $orange;
          margin: 0;
          padding: 0;
          border: none;
          outline: none;
          background: none;
          height: auto;
          font-size: 1.8rem;
          font-weight: 600;
          letter-spacing: 0.025em;
          font-family: $helv-cond_medium;
          &:hover, &:focus{
            color: $grey;
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 999px) {
  .col3Box{
    margin-bottom: 3.5rem;
    .box{
      margin-bottom: 1.5rem;
    }
  }
}