//COLORS:
//** COLORS **//
$grey-ultralight: #eef0f1 !default;
$grey-light: #c3cacd !default;
$grey: #8b969a !default;
$grey-darker: #757e82 !default;
$grey-darkest: #5c6467 !default;

$font: $grey-darkest;
$orange: #ff870d !default;



// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 1000px !default;
$screen-md-min: $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1340px !default;
$screen-lg-min: $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;
// BS GRID
$grid-columns: 12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 60px !default;

//Container sizes
$container-tablet: (750px) !default;
$container-sm: $container-tablet !default;
$container-desktop: (972px) !default;
$container-md: $container-desktop !default;
$container-large-desktop: (1240px + $grid-gutter-width) !default;
$container-lg: $container-large-desktop !default;

$screen-xs: 480px !default;
$screen-sm: 768px !default;
$screen-md: 1000px !default;
$screen-lg: 1300px !default;

$grid-float-breakpoint: $screen-md-min !default;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;

// Basics of a navbar
$navbar-height: auto !default;
$navbar-collapse-max-height: 100vh !default;

$navbar-default-color: $grey-light !default;
$navbar-default-bg: #fff !default;

// Navbar links
$navbar-default-link-color: $orange !default;
$navbar-default-link-hover-color: $orange !default;
$navbar-default-link-hover-bg: transparent !default;
$navbar-default-link-active-color: $orange !default;
$navbar-default-link-active-bg: #fff !default;
$navbar-default-link-disabled-color: #ccc !default;
$navbar-default-link-disabled-bg: transparent !default;

// Navbar brand label
$navbar-default-brand-color: $orange !default;
$navbar-default-brand-hover-color: $orange !default;
$navbar-default-brand-hover-bg: transparent !default;

// Navbar toggle
$navbar-default-toggle-hover-bg: #fff !default;
$navbar-default-toggle-icon-bar-bg: #000 !default;
$navbar-default-toggle-border-color: transparent !default;

$font-family-sans-serif: arial, helvetica, sans-serif;
$font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;

$font-size-base: 10px !default;
$font-size-large: ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small: ceil(($font-size-base * 0.85)) !default; // ~12px

$font-size-h1: 3.6rem !default;
$font-size-h2: 2.8rem !default;
$font-size-h3: 2.0rem !default;
$font-size-h4: 1.6rem !default;
$font-size-h5: 1.2rem !default;
$font-size-h6: 1.0rem !default;


//** Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.618 !default; // 20/14



//TRANSITIONS===================//
$t-type:cubic-bezier(0.785, 0.135, 0.150, 0.860);
$t-fast:all $t-type 0.2s;
$t-medium:all $t-type 0.3s;
$t-slow:all $t-type 0.4s;


//MIXINS=======================//
@mixin hl1{

}


