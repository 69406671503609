/*BOX*/
.box{
  background: $grey-ultralight;
  padding: 25px 30px 30px;
  position: relative;
  .infobox{
    background: $orange;
    color: #fff;
    padding: 25px 30px 30px;
    margin: -25px -30px -30px;
    p{
      font-size: 2.8rem;
    }
    > *{
      color: #fff !important;
    }
    .btn{
      background: #fff;
      color: $font;
      &:before{
        background: $font;
      }
      &:hover, &:focus{
        color: #fff;
      }
    }
    &:after{
      position: absolute;
      content: url("../Images/info.svg");
      display: block;
      width: 46px;
      height: 46px;
      right: 0;
      top:0;
      transform: translate3D(50%, -50%, 0);
    }
    ul li {
      list-style-image: url(../Images/li-bullet_white.gif);
    }
  }
}



/*==========  BREAKPOINTS  MAX-WIDTH ==========*/


@media only screen and (max-width: $screen-lg) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/


@media only screen and (max-width: $screen-md-max) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/


@media only screen and (max-width: $screen-sm-max) {

}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/


@media only screen and (max-width: $screen-xs-max) {

}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/


@media only screen and (max-width: 374px) {

}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/


@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/



/*==========  BREAKPOINTS  MIN-WIDTH ==========*/


@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/


@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/


@media only screen and (min-width: $screen-xs) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: $screen-sm) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/

@media only screen and (min-width: $screen-md) {

}



/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/