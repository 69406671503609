/*BUTTONS*/

.btn {
  font-family: $helv-cond_medium;
  font-weight: 600;
  font-size: 2.4rem;
  display: inline-block;
  border: none;
  background: #fff;
  border:1px solid $grey-light;
  padding: 1.2rem 2rem;
  text-align: center;
  min-width: 200px;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  z-index: 10;
  margin-top: 3rem;
  transition: $t-medium;
  color: $font;
  &.btn-small{
    font-size: 1.8rem;
    padding: 0.8rem 1.6rem;
    margin: 1.5rem 0;
  }
  &.btn-block {
    display: block;
    width: 100%;
  }
  &:hover, &:focus {
    color: $orange;
    background: $grey-ultralight;
    border-color: $orange;
  }
  &.highlight{
    border-color: $orange;
    &:hover, &:focus {
      color: #fff;
      background: $orange;
      border-color: $orange;
    }
  }
  &.orange{
    border-color: $orange;
    background-color: $orange;
    color: #fff;
    &:hover, &:focus {
      color: #fff;
      background: $font;
      border-color: $font;
    }
  }
}

a.link {
  text-decoration: none;
  margin: 20px 0;
  color: $orange;
  display: inline-block;
  transition: $t-fast;
  &:before, &:after {
    transition: $t-fast;
  }
  &:before {
    content: "> ";
    display: inline;
  }
  &:after {
    content: " >";
    display: inline;
    opacity: 0;
  }
  &:hover, &:focus, &:active {
    color: $grey;
    &:before {
      opacity: 0;
    }
    &:after {
      opacity: 1;
    }
  }
}

/*==========  BREAKPOINTS  MAX-WIDTH ==========*/

@media only screen and (max-width: 1339px) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 999px) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 767px) {

}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/

@media only screen and (max-width: 479px) {
  .btn {
  }
}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/

@media only screen and (max-width: 374px) {

}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/

@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/

/*==========  BREAKPOINTS  MIN-WIDTH ==========*/

@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/

@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/

@media only screen and (min-width: 480px) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: 768px) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/

@media only screen and (min-width: 1000px) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/